<template>
  <div class="md:w-9/12 mx-auto px-2 md:px-8 py-12">
    <trac-loading v-if="isLoading" />
    <h3 class="my-5">Buy Airtime and Data</h3>
    <div class="flex justify-between">
      <div
        class="
          bg-accentLight
          rounded-md
          px-4
          py-2
          flex
          justify-evenly
          items-center
          gap-3
        "
      >
        <input
          type="radio"
          name="transaction-type"
          id="airtime"
          class="cursor-pointer"
          v-model="transactionType"
          value="airtime"
        />
        <label for="airtime" class="cursor-pointer text-xs"> Airtime </label>
        <img
          class="p-2"
          src="./../../../../assets/svg/wallet/tablet.svg"
          alt=""
        />
      </div>
      <div
        class="
          bg-accentLight
          rounded-md
          px-4
          py-2
          flex
          justify-evenly
          items-center
          gap-3
        "
      >
        <input
          type="radio"
          name="transaction-type"
          id="data"
          class="cursor-pointer"
          v-model="transactionType"
          value="data"
        />
        <label for="data" class="cursor-pointer text-xs"> Data Plan </label>
        <img
          class="p-2"
          src="./../../../../assets/svg/wallet/globe.svg"
          alt=""
        />
      </div>
    </div>
    <div class="flex justify-center my-4">
      <img
        class="p-2"
        alt=""
        :src="selectedNetwork.img"
        width="100"
      />
      <!-- src="./../../../../assets/svg/wallet/9mobile_logo.svg" -->
    </div>
    <div class="my-4">
      <label for="phone-number" class="inline-block mb-2 text-xs"
        >Type in mobile number</label
      >
      <trac-input v-model="phoneNumber" id="phone-number"></trac-input>
      <br />
      <div class="" v-if="transactionType === 'data'">
        <label for="phone-number" class="inline-block mb-2 text-xs"
          >Select your data plan</label
        >
        <trac-dropdown-exteneded
          :neededProperty="'name'"
          :options="plans"
          :selector="'Data plan'"
          @optionSelected="selectDataPlan($event)"
        ></trac-dropdown-exteneded>
      </div>
      <div class="" v-else>
        <label for="phone-number" class="inline-block mb-2 text-xs"
          >How much do you want to Top-up?</label
        >
        <trac-input v-model.number="enteredVoucherAmount" id=""></trac-input>
      </div>
    </div>
    <div class="flex justify-end mt-8">
      <trac-button @button-clicked="selectTypeOfTransaction" class=""
        >Proceed to payment</trac-button
      >
    </div>
  </div>
</template>

<script>
export default {
  props: ["selectedNetwork"],
  data() {
    return {
      isLoading: false,
      transactionType: "airtime",
      planSelected: "",
      enteredVoucherAmount: "",
      phoneNumber: "",
      plans: [],
      // plans: [
      //   {
      //     name: "100MB for 1 Day (₦100)",
      //     value: 100,
      //   },
      //   {
      //     name: "200MB for 3 Days (₦200)",
      //     value: 200,
      //   },
      //   {
      //     name: "350MB for 7 Days (₦300)",
      //     value: 300,
      //   },
      //   {
      //     name: "750MB for 14 Days (₦500)",
      //     value: 500,
      //   },
      // ],
    };
  },
  created() {
    // console.log(this.selectedNetwork);
    // console.log(`./../../../../${this.selectedNetwork.img}`);
    this.$emit('activate-data-selection'); // Continue here
  },
  async fetchWalletDetails() {
    // this.walletDetails = this.$store.getters["GET_WALLET_DETAILS"] || {})[
    //   "data"
    // ];

    if (!this.walletDetails) {
      await this.$store.dispatch("FETCH_WALLET_DETAILS");
      this.walletDetails = (this.$store.getters["GET_WALLET_DETAILS"] || {})[
        "data"
      ];
    }
  },
  watch: {
    transactionType: async function() {
      if (this.plans.length === 0) {
        this.isLoading = true;
        await this.fetchTariff();
        this.isLoading = false;
      }
    }
  },
  methods: {
    async fetchTariff() {
      const res = await this.$store.dispatch('FETCH_DATA_PLANS', {
        telcosName: this.selectedNetwork.telcosName,
      });

      this.plans = res.data.content.varations;
    },
    selectDataPlan(planSelected) {
      this.planSelected = planSelected;
    },
    selectTypeOfTransaction() {
      const transactionData = {
        transactionType: this.transactionType,
        phoneNumber: this.phoneNumber,
        service: this.selectedNetwork,
        planSelected:
          this.transactionType === "airtime"
            ? +this.enteredVoucherAmount
            : this.planSelected,
      };
      this.$emit("select-type-of-transaction", transactionData);
    },
  },
};
</script>

<style>
</style>