<template>
  <div class="py-10 px-6">
    <h2 class="font-semibold text-xs my-4">Review Details</h2>
    <div class="mt-8 shadow-md rounded-md p-6">
      <div class="flex justify-between items-end">
        <div class="flex items-center">
          <div
            class="
              smallest
              h-12
              w-12
              flex
              justify-center
              items-center
              rounded-full
              mr-4
              bg-accentLight
            "
          >
            <p
              class="text-accentDark font-thin text-lg cursor-pointer uppercase"
            >
              JD
            </p>
          </div>
          <div class="">
            <p class="text-xs font-bold">John Doe</p>
            <p class="text-xs font-light text-gray-600">
              123456789012342 | FirstBank
            </p>
          </div>
        </div>
        <h2 class="font-extrabold text-xl">NGN 12,00,000</h2>
      </div>
      <div class="mt-8 text-xs grid grid-cols-2">
        <div class="">
          <p class="font-light text-gray-600">Transfer Amount</p>
          <h3 class="text-base font-bold">NGN 10,000</h3>
        </div>
        <div class="">
          <p class="font-light text-gray-600">Transfer Date</p>
          <p class="font-semibold">17 Sep, 2021</p>
        </div>
      </div>
    </div>
    <div class="flex justify-end mt-10">
      <trac-button @button-clicked="$emit('trnasfer-funds')">Transfer now</trac-button>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>