<template>
  <div class="">
    <div class="mx-auto px-8 py-12">
      <h3 class="my-5 font-bold">Pay Now</h3>
      <div class="mt-6">
        <div class="flex justify-center my-8">
          <input
            type="password"
            name=""
            id=""
            class="
              border
              w-10
              text-center
              font-bold
              p-2
              inline-block
              mx-4
              rounded-md
            "
          />
          <input
            type="password"
            name=""
            id=""
            class="
              border
              w-10
              text-center
              font-bold
              p-2
              inline-block
              mx-4
              rounded-md
            "
          />
          <input
            type="password"
            name=""
            id=""
            class="
              border
              w-10
              text-center
              font-bold
              p-2
              inline-block
              mx-4
              rounded-md
            "
          />
          <input
            type="password"
            name=""
            id=""
            class="
              border
              w-10
              text-center
              font-bold
              p-2
              inline-block
              mx-4
              rounded-md
            "
          />
        </div>
        <p class="text-center text-xs font-bold">
          Enter your 4 Digit security PIN
        </p>
      </div>
      <div class="flex justify-center my-12">
        <trac-button class="w-1/2" @button-clicked="confirmTransactionPIN"
          >Submit</trac-button
        >
      </div>
      <div class="mt-8 shadow-md rounded-md p-6">
      <div class="flex justify-between items-end">
        <div class="flex items-center">
          <div
            class="
              smallest
              h-12
              w-12
              flex
              justify-center
              items-center
              rounded-full
              mr-4
              bg-accentLight
            "
          >
            <p
              class="text-accentDark font-thin text-lg cursor-pointer uppercase"
            >
              JD
            </p>
          </div>
          <div class="">
            <p class="text-xs font-bold">John Doe</p>
            <p class="text-xs font-light text-gray-600">
              123456789012342 | FirstBank
            </p>
          </div>
        </div>
        <h2 class="font-extrabold text-xl">NGN 12,00,000</h2>
      </div>
      <div class="mt-8 text-xs grid grid-cols-2">
        <div class="">
          <p class="font-light text-gray-600">Transfer Amount</p>
          <h3 class="text-base font-bold">NGN 10,000</h3>
        </div>
        <div class="">
          <p class="font-light text-gray-600">Transfer Date</p>
          <p class="font-semibold">17 Sep, 2021</p>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pin1: "",
      pin2: "",
      pin3: "",
      pin4: "",
    };
  },
  methods: {
    confirmTransactionPIN() {
      const enteredPin = this.pin1 + this.pin2 + this.pin3 + this.pin4;
      
      this.$emit("add-transfer-transaction-pin", {
        pin: enteredPin,
      });
    },
  },
};
</script>

<style>
</style>