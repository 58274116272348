<template>
  <div class="">
    <div class="flex justify-center mt-8">
      <img
        class="p-2"
        @click="selectedNetwork = 'glo'"
        src="./../../../assets/svg/wallet/success_logo.svg"
        alt=""
      />
    </div>
    <h2 class="font-semibold text-lg text-center my-4">Payment Successful!</h2>
    <p class="text-center my-4 text-xs">
      Your transaction was completed successfully
    </p>
    <p class="text-center text-gray-600 mt-4 mb-8">
      Amount paid <span class="font-bold text-black">{{ responsePayload.amount | formatPrice(false) }}</span>
    </p>
    <hr class="my-10" />
    <p class="text-center font-bold text-xs">Your Request History</p>
    <div class="grid grid-cols-2 w-2/3 text-xs my-6 mx-auto">
      <p class="text-gray-600">Your service provider</p>
      <span class="text-right font-bold uppercase">{{ transactionData.service.telcosName }}</span>
      <p class="text-gray-600">Phone Number</p>
      <span class="text-right font-bold">(+234) {{ transactionData.phoneNumber }}</span>
      <p class="text-gray-600">Top-up Amount</p>
      <span class="text-right font-bold">{{ responsePayload.amount | formatPrice(false) }}</span>
    </div>
    <p class="text-center text-xs">
      {{ moment().format("Do MMM, YYYY | hh:mm a") }} | Transaction ID - {{ responsePayload.reference }}
      <!-- {{ responsePayload.updatedOn }} | Transaction ID - {{ responsePayload.reference }} -->
    </p>
    <div class="flex justify-center my-12">
      <trac-button @button-clicked="closeTransactionModal" class="w-1/2">Done</trac-button>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      moment,
    };
  },
  created() {
  },
  props: ['transactionData', 'selectedNetwork', 'responsePayload'],
  methods: {
    closeTransactionModal() {
      this.$emit('close-transaction-modal');
    }
  }
};
</script>

<style>
</style>