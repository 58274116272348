<template>
  <div class="select-network">
    <div
      class="
        h-96
        flex flex-col
        justify-center
        items-center
        service-provider-hold
      "
    >
      <h3 class="mb-3">Select your service provider</h3>
      <div class="grid grid-cols-4 gap-2 md:gap-6 shadow-md px-8 py-2">
        <div class="mt-4 cursor-pointer">
          <input
            class="hidden"
            type="radio"
            name="service-provider"
            v-model="selectedNetwork"
            value="mtn"
          />
          <img
            class="p-2"
            @click="selectedNetwork = 'mtn'"
            src="./../../../../assets/svg/wallet/mtn_logo.svg"
            alt=""
          />
        </div>
        <div class="mt-4 cursor-pointer">
          <input
            class="hidden"
            type="radio"
            name="service-provider"
            v-model="selectedNetwork"
            value="airtel"
          />
          <img
            class="p-2"
            @click="selectedNetwork = 'airtel'"
            src="./../../../../assets/svg/wallet/airtel_logo.svg"
            alt=""
          />
        </div>
        <div class="mt-4 cursor-pointer">
          <input
            class="hidden"
            type="radio"
            name="service-provider"
            v-model="selectedNetwork"
            value="etisalat"
          />
          <img
            class="p-2"
            @click="selectedNetwork = 'etisalat'"
            src="./../../../../assets/svg/wallet/9mobile_logo.svg"
            alt=""
          />
        </div>
        <div class="mt-4 cursor-pointer">
          <input
            class="hidden"
            type="radio"
            name="service-provider"
            v-model="selectedNetwork"
            value="glo"
          />
          <img
            class="p-2"
            @click="selectedNetwork = 'glo'"
            src="./../../../../assets/svg/wallet/glo_logo.svg"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="flex justify-end">
      <trac-button
        @button-clicked="setServiceProvider"
        :disabled="selectedNetwork === ''"
        >CONTINUE</trac-button
      >
    </div>
  </div>
</template>

<script>
export default {
  // name: 'NetworkSelection'
  data() {
    return {
      selectedNetwork: "",
    };
  },
  methods: {
    setServiceProvider() {
      this.$emit("set-service-provider", {
        serviceProvider: this.selectedNetwork,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.service-provider-hold {
  input:checked ~ img {
    border: 0.1rem solid #003283;
    border-radius: 0.3rem;
  }
}
</style>