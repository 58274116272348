<template>
  <div class="py-10 px-6">
    <h2 class="font-semibold text-xs my-4">Make Transfer</h2>
    <h4 class="font-extrabold text-sm mt-10">To Beneficiary</h4>
    <div class="flex justify-between items-end mt-8">
      <div class="flex items-center">
        <div
          class="
            smallest
            h-12
            w-12
            flex
            justify-center
            items-center
            rounded-full
            mr-4
            bg-accentLight
          "
        >
          <p class="text-accentDark font-thin text-lg cursor-pointer uppercase">
            JD
          </p>
        </div>
        <div class="">
          <p class="text-xs font-bold">John Doe</p>
          <p class="text-xs font-light text-gray-600">
            123456789012342 | FirstBank
          </p>
        </div>
      </div>
      <h2 class="font-extrabold text-xl">NGN 12,00,000</h2>
    </div>

    <div class="mt-12 w-10/12">
      <trac-input placeholder="Amount to transfer"></trac-input>

      <trac-dropdown-exteneded
        class="mt-8"
        :neededProperty="'name'"
        :options="beneficiaries"
        :selector="'Select Benficiary'"
        @optionSelected="selectBeneficiary($event)"
      ></trac-dropdown-exteneded>
      <div class="mt-6 flex justify-end">
        <trac-button @button-clicked="addBeneficiary" class="w-5/12">PROCEED</trac-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      beneficiaries: [
        {
          name: "John Doe",
        },
        {
          name: "Anna White",
        },
        {
          name: "Steph Moray",
        },
        {
          name: "Jedi Scort",
        },
      ],
    };
  },
  methods: {
    selectBeneficiary(e) {
      // console.log(e);
    },
    addBeneficiary() {
      this.$emit('select-benficiary', )
    }
  },
};
</script>

<style>
</style>