<template>
  <main class="mr-4">
    <trac-loading v-if="isLoading" />
    <trac-modal
      v-if="transferToSettlementAccountModalCtrl"
      @close="transferToSettlementAccountModalCtrl = false"
    >
      <trac-beneficiary-transfer
        :selectedBeneficiary="currentlySlectedBeneficiary"
        :walletDetails="walletDetails"
        @transferDetailsEmitter="makeTransferToBeneficiary"
      ></trac-beneficiary-transfer>
    </trac-modal>
    <!-- Airtime Modal -->
    <trac-modal @close="closeTransactionModal" v-if="buyAirtimeModalCtrl">
      <trac-network-selection
        @set-service-provider="addServiceProvider"
        v-if="currentAirtimeIndex === 1"
      ></trac-network-selection>
      <trac-airtime-data
        @select-type-of-transaction="selectTypeOfTransaction"
        @activate-data-selection="fetchTelcomsDataInfo"
        v-if="currentAirtimeIndex === 2"
        :selectedNetwork="selectedNetwork"
      ></trac-airtime-data>
      <trac-confirm-request
        @got-enter-pin="currentAirtimeIndex += 1"
        :selectedNetwork="selectedNetwork"
        :transactionData="transactionData"
        :walletData="walletDetails"
        v-if="currentAirtimeIndex === 3"
      ></trac-confirm-request>
      <trac-transaction-pin
        :selectedNetwork="selectedNetwork"
        :transactionData="transactionData"
        :walletData="walletDetails"
        @add-transaction-pin="addtransactionPin"
        v-if="currentAirtimeIndex === 4"
      ></trac-transaction-pin>
      <trac-transaction-success
        :selectedNetwork="selectedNetwork"
        :transactionData="transactionData"
        :responsePayload="responsePayload"
        @close-transaction-modal="closeTransactionModal"
        v-if="currentAirtimeIndex === 5"
      ></trac-transaction-success>
    </trac-modal>

    <!-- Transfer Modal -->
    <trac-modal @close="transferModalCtrl = false" v-if="transferModalCtrl">
      <trac-add-beneficiary
        v-if="currentTransferIndex === 1"
        @select-benficiary="currentTransferIndex += 1"
      ></trac-add-beneficiary>
      <trac-transfer-transaction
        v-if="currentTransferIndex === 2"
        @trnasfer-funds="currentTransferIndex += 1"
      ></trac-transfer-transaction>
      <trac-transfer-transaction-pin
        v-if="currentTransferIndex === 3"
        @add-transfer-transaction-pin="currentTransferIndex += 1"
      ></trac-transfer-transaction-pin>
      <trac-transaction-success
        @close-transaction-modal="closeTransactionModal"
        v-if="currentTransferIndex === 4"
      ></trac-transaction-success>
    </trac-modal>

    <!-- <div class="flex items-center">
      <svg
        class="w-3 h-3"
        viewBox="0 0 9 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.5 15.5L0.5 8.5L7.5 1.5"
          stroke="#333333"
          stroke-width="1.16667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <div class="text-xs ml-3 leading-6">Account Details</div>
    </div> -->
    <div
      class="m-auto mt-20 w-7/12 flex flex-col justify-center items-center"
      v-if="!walletDetails && !isLoading"
    >
      <img width="200" src="./../../assets/svg/no-data.svg" alt="" />
      <p class="mt-6">No Wallet Data</p>
      <p class="text-xs text-gray-600 text-center my-3">
        This is because you haven't setup your settlement account. Not to worry,
        click the button below to set this up. It will only take a few seconds.
      </p>
      <trac-button
        @button-clicked="
          $router.push({ name: 'AddBankDetails', query: { onboarding: true } })
        "
        >Setup KYC</trac-button
      >
    </div>
    <div class="mt-10 w-full grid wallet-info-cards gap-2" v-if="walletDetails">
      <div class="p-4 wallet-bg flex-1 rounded-lg">
        <div class="grid grid-cols-2 items-center">
          <div class="flex flex-1 items-center">
            <img src="@/assets/svg/wallet_house.svg" class="w-20 h-20" alt="" />
            <div class="ml-3">
              <h1 class="text-xs text-primaryGray">Balance</h1>
              <div class="text-xl uppercase font-medium md:mb-0 mb-5">
                {{ walletDetails.amount | formatPrice(false) }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-end items-end">
          <trac-button @button-clicked="transferToSettlementAccount"
            >Transfer to settlement Account</trac-button
          >
        </div>
      </div>
      <div class="p-5 wallet-bg flex-1 rounded-lg">
        <h1 class="text-lg font-medium">Quick Links</h1>
        <div class="flex justify-between px-5 h-full pb-4 items-center">
          <!-- @click="$router.push({ name: 'WalletMainTransfer' })" -->
          <div
            class="flex justify-center items-center flex-col cursor-pointer"
            @click="openBeneficiary"
          >
            <img src="@/assets/svg/make_transfer.svg" alt="" />
            <p class="text-xs text-center mt-2 leading-4">
              Make <br />
              Transfer
            </p>
          </div>
          <div
            @click="openBuyAirtimeModal"
            class="flex justify-center items-center flex-col cursor-pointer"
          >
            <img src="@/assets/svg/buy_airtime.svg" alt="" />
            <p class="text-xs text-center mt-2 leading-4">
              Buy <br />
              Airtime
            </p>
          </div>
          <!-- <div
            @click="openBeneficiary"
            class="flex justify-center items-center flex-col cursor-pointer"
          >
            <img src="@/assets/svg/account_statement.svg" alt="" />
            <p class="text-xs text-center mt-2 leading-4">
              Manage
              <br />
              Beneficiaries
            </p>
          </div> -->
          <div
            class="flex justify-center items-center flex-col cursor-pointer"
            @click="$router.push({ name: 'PinManagment' })"
          >
            <img src="@/assets/svg/account_statement.svg" alt="" />
            <p class="text-xs text-center mt-2 leading-4">
              PIN <br />
              Management
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-10" v-if="contents && walletDetails">
      <div class="mt-5 mb-2 text-xl">Transactions</div>
      <div class="grid md:flex md:justify-between items-end">
        <div class="md:w-1/2 mb-3 md:mb-0">
          <trac-dropdown-exteneded
            :neededProperty="'name'"
            :options="timespan"
            :selector="timespan[0].name"
            @optionSelected="selectTimeline($event)"
          ></trac-dropdown-exteneded>
        </div>

        <div class="">
          <button
            @click="selectedFilter = 'all'"
            style="outline: none"
            class="
              text-xs
              outline-none
              border-0
              text-primaryBlue
              border-primaryBlue
              px-6
              py-2
            "
            :class="selectedFilter == 'all' ? 'border-b-2' : ''"
          >
            All
          </button>
          <button
            @click="selectedFilter = 'in'"
            style="outline: none"
            class="
              text-xs
              outline-none
              border-0
              text-primaryBlue
              border-primaryBlue
              px-6
              py-2
            "
            :class="selectedFilter == 'in' ? 'border-b-2' : ''"
          >
            In
          </button>
          <button
            @click="selectedFilter = 'out'"
            style="outline: none"
            class="
              text-xs
              outline-none
              border-0
              text-primaryBlue
              border-primaryBlue
              px-6
              py-2
            "
            :class="selectedFilter == 'out' ? 'border-b-2' : ''"
          >
            Out
          </button>
        </div>
      </div>
      <!--  -->
      <div class="md:hidden">
        <div
          class="text-xs py-4"
          v-for="(transaction, i) in filteredTransactions"
          :key="i"
        >
          <p class="mb-2 text-gray-600">
            {{ moment(transaction.updatedOn).format("DD MMM YYYY") }}
          </p>
          <div class="flex justify-between">
            <p class="">{{ transaction.craccountname }}</p>
            <span
              class="font-bold"
              :class="transaction.isCredit ? 'text-green-500' : 'text-red-500'"
              >{{ transaction.isCredit ? "+" : "-" }}<span class=""></span>
              {{ transaction.amount | formatPrice }}</span
            >
          </div>
          <div class="">
            <p
              class="small mt-1 font-bold"
              :class="transaction.isCredit ? 'text-green-500' : 'text-red-500'"
            >
              {{ transaction.transactiontypedisplaytext }}
            </p>
          </div>
          <div class="flex justify-end">
            <button
              class="font-bold text-primaryBlue outline-none"
              @click="openWalletTransaction(transaction)"
            >
              View details
            </button>
          </div>
        </div>
        <div v-if="filteredTransactions.length === 0" class="flex justify-center items-center h-60 text-sm">
          No transactions added yet.
        </div>
      </div>
      <div class="w-full overflow-scroll hidden md:block">
        <table class="w-full">
          <thead>
            <tr class="bg-accentLight">
              <th class="text-xs text-left px-2 py-4">Name</th>
              <!-- <th class="text-xs text-left px-2 py-4">Payment Method</th> -->
              <th class="text-xs text-left px-2 py-4">Date</th>
              <th class="text-xs text-left px-2 py-4">Transaction ID</th>
              <th class="text-xs text-left px-2 py-4">Amount</th>
              <!-- <th class="text-xs text-left px-2 py-4">Balance</th> -->
            </tr>
          </thead>
          <tbody v-if="filteredTransactions.length > 0">
            <tr
              v-for="(transaction, i) in filteredTransactions"
              :key="i"
              @click="openWalletTransaction(transaction)"
              class="cursor-pointer hover:bg-veryLight"
            >
              <td class="text-xs p-3 flex items-center">
                <div
                  class="
                    smallest
                    h-8
                    w-8
                    flex
                    justify-center
                    items-center
                    rounded-full
                    mr-4
                    bg-accentLight
                  "
                >
                  <div class="tiny text-accentDark cursor-pointer uppercase">
                    {{
                      transaction.craccountname
                        .split(" ")
                        .map((name) => name.substring(0, 1))
                        .join("")
                    }}
                  </div>
                </div>
                <span class="font-extrabold">
                  {{ transaction.craccountname }}
                </span>
              </td>
              <!-- <td class="text-xs p-3">{{ transaction.paymentMethod }}</td> -->
              <td class="text-xs p-3">
                {{ moment(transaction.updatedOn).format("DD MMM YYYY") }}
              </td>
              <td class="text-xs p-3">{{ transaction.reference }}</td>
              <td
                class="text-xs p-3 font-bold"
                :class="
                  transaction.isCredit ? 'text-green-500' : 'text-red-500'
                "
              >
                {{ transaction.isCredit ? "+" : "-" }}<span class=""></span>
                {{ transaction.amount | formatPrice }}
              </td>
              <!-- <td class="text-xs p-3 font-bold">
                {{ transaction.lastBalance | formatPrice }}
              </td> -->
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="5" class="">
                <div class="flex justify-center items-center h-60 text-sm">
                  No transactions added yet.
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </main>
</template>

<script>
import moment from "moment";
import MakeTransferVue from "./beneficiaries/beneficiaries-components/MakeTransfer.vue";
import NetworkSelection from "./wallet-components/airtime-transaction/ServiceProviderSelection.vue";
import AirtimeData from "./wallet-components/airtime-transaction/AirtimeData.vue";
import ConfirmRequest from "./wallet-components/ConfirmRequest.vue";
import TransactionPin from "./wallet-components/airtime-transaction/TransactionPin.vue";
import TransactionSuccess from "./wallet-components/TransactionSuccess.vue";
import AddBeneficiary from "./wallet-components/transafer-transaction/AddBeneficiary.vue";
import ReviewTransferTransactionVue from "./wallet-components/transafer-transaction/ReviewTransferTransaction.vue";
import TransferTransactionPin from "./wallet-components/transafer-transaction/TransactionPin.vue";
import { eventBus } from "./../../main";
import {
  GET_USER_BUSINESS_DATA,
  SAVE_LOCAL_DB_DATA,
} from "../../browser-db-config/localStorage";

export default {
  components: {
    "trac-network-selection": NetworkSelection,
    "trac-airtime-data": AirtimeData,
    "trac-confirm-request": ConfirmRequest,
    "trac-transaction-pin": TransactionPin,
    "trac-transaction-success": TransactionSuccess,
    "trac-add-beneficiary": AddBeneficiary,
    "trac-transfer-transaction": ReviewTransferTransactionVue,
    "trac-transfer-transaction-pin": TransferTransactionPin,
    "trac-beneficiary-transfer": MakeTransferVue,
  },
  data() {
    return {
      moment,
      currentAirtimeIndex: 1,
      currentTransferIndex: 1,
      selectedFilter: "all",
      isLoading: false,
      transferToSettlementAccountModalCtrl: false,
      buyAirtimeModalCtrl: false,
      transferModalCtrl: false,
      selectedNetwork: null,
      transactionData: null,
      transactionPinData: null,
      contents: null,
      walletDetails: null,
      currentlySlectedBeneficiary: null,
      telcoms: null,
      responsePayload: null,
      banks: null,
      timespan: [
        {
          name: "Last 7 days",
          value: 7,
        },
        {
          name: "Last 10 days",
          value: 10,
        },
        {
          name: "Last 30 days",
          value: 30,
        },
        {
          name: "Last 90 days",
          value: 90,
        },
      ],
      telcomsData: [
        {
          imgUrl:
            "https://s3-us-west-1.amazonaws.com/tractionapp-products/pkg/app/uploads/6254147922079755883Frame415.png",
          name: "mtn",
        },
        {
          imgUrl:
            "https://s3-us-west-1.amazonaws.com/tractionapp-products/pkg/app/uploads/8719804327786045052Frame413.png",
          name: "airtel",
        },
        {
          imgUrl:
            "https://s3-us-west-1.amazonaws.com/tractionapp-products/pkg/app/uploads/4408934263993191813Frame414.png",
          name: "etisalat",
        },
        {
          imgUrl:
            "https://s3-us-west-1.amazonaws.com/tractionapp-products/pkg/app/uploads/5630710232234883698Frame412.png",
          name: "glo",
        },
      ],
      // contents: [
      //   {
      //     name: "Starbucks",
      //     paymentMethod: "Pos Transaction",
      //     date: new Date().toISOString(),
      //     transactionId: "1233456789098765",
      //     amount: "+2345606",
      //     balance: 43333333,
      //   },
      //   {
      //     name: "Fractalink des..",
      //     paymentMethod: "Cash",
      //     date: new Date().toISOString(),
      //     transactionId: "1233456789098765",
      //     amount: "-24,2526",
      //     balance: 3891000,
      //   },
      // ],
    };
  },
  computed: {
    filteredTransactions() {
      return this.contents.filter((transaction) => {
        if (this.selectedFilter === "in") {
          return transaction.isCredit;
        } else if (this.selectedFilter === "out") {
          return !transaction.isCredit;
        } else {
          return transaction;
        }
      });
    },
  },
  async created() {
    this.isLoading = true;

    await this.fetchWalletDetails();
    await this.fetchTransactionLogs(this.timespan[0]["value"]);

    this.isLoading = false;
  },
  methods: {
    openWalletTransaction(transaction) {
      SAVE_LOCAL_DB_DATA("wallet_transaction", transaction);
      this.$router.push({ name: "walletTransactionDetails" });
    },
    async selectTimeline(timeline) {
      this.isLoading = true;
      if (!this.walletDetails) {
        await this.fetchWalletDetails();
      }
      await this.fetchTransactionLogs(timeline["value"]);

      this.isLoading = false;
    },
    async fetchTransactionLogs(timelineDays) {
      const transactionsRes = await this.$store.dispatch(
        "FETCH_TRANSACTION_LOGS",
        { ...this.walletDetails, timelineDays }
      );

      if (transactionsRes.status) {
        this.contents = transactionsRes.data;
      } else {
        eventBus.$emit("trac-alert", {
          message: "Error: Couldn't get bank details.",
        });
      }
    },
    async fetchAllBankAccounts() {
      await this.$store.dispatch("FETCH_ALL_BANK_ACCOUNTS");
      const res = this.$store.getters["GET_ALL_BANK_ACCOUNTS"];

      if (res.status) {
        this.banks = res.data || [];

        this.currentlySlectedBeneficiary = {
          bankcode: this.banks[0]["traction_bank_code"],
          bankname: this.banks[0]["settlement_bank"],
          accountnumber: this.banks[0]["account_number"],
          accountname: this.banks[0]["business_name"],
          // Not really needed in this payload
          draccountname: GET_USER_BUSINESS_DATA().name,
          narration: "Transferred to settlement account.",
        };
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
    },
    async makeTransferToBeneficiary(payload) {
      this.isLoading = true;

      const beneficiaryTransferRes = await this.$store.dispatch(
        "TRANSFER_TO_BENEFICIARY",
        {
          transferDetails: payload,
          wallet: this.walletDetails,
        }
      );

      if (beneficiaryTransferRes.status) {
        await this.fetchWalletDetails();

        this.transferToSettlementAccountModalCtrl = false;
        eventBus.$emit("trac-alert", {
          message:
            beneficiaryTransferRes.message || "Funds transferred successfully.",
        });
      } else {
        // console.log(beneficiaryTransferRes);
        eventBus.$emit("trac-alert", {
          message:
            beneficiaryTransferRes.message.message || beneficiaryTransferRes.message || "Error: Couldn't make transfer.",
        });
      }
      this.isLoading = false;
    },
    async transferToSettlementAccount() {
      this.isLoading = true;
      await this.fetchAllBankAccounts();

      if (this.banks) {
        this.transferToSettlementAccountModalCtrl = true;
      }

      this.isLoading = false;
    },
    async openBuyAirtimeModal() {
      this.isLoading = true;
      const telcomsRes = await this.$store.dispatch("FETCH_TELCOMS");

      if (telcomsRes.status) {
        const fetchedTelcoms = telcomsRes.data;

        for (let index = 0; index < fetchedTelcoms.length; index++) {
          const element = fetchedTelcoms[index];
          this.telcomsData.forEach((tel) => {
            if (tel.name === element.telcosName) {
              fetchedTelcoms[index] = {
                ...fetchedTelcoms[index],
                img: tel.imgUrl,
              };
            }
          });
        }

        this.telcoms = fetchedTelcoms;
        this.buyAirtimeModalCtrl = true;
      } else {
        eventBus.$emit("trac-alert", {
          message:
            telcomsRes.message || "Error: Unable to fetch service providers.",
        });
      }
      this.isLoading = false;
    },
    openBeneficiary() {
      this.$router.push({ name: "WalletBeneficiaries" });
    },
    addServiceProvider(selectedNetwork) {
      this.selectedNetwork = this.telcoms.find(
        (telcom) => selectedNetwork.serviceProvider === telcom.telcosName
      );
      this.currentAirtimeIndex += 1;
    },
    async selectTypeOfTransaction(transactionData) {
      this.transactionData = transactionData;
      this.currentAirtimeIndex += 1;
    },
    async addtransactionPin(transactionPinData) {
      this.isLoading = true;
      // this.transactionPinData = transactionPinData;
      // console.log(this.transactionPinData);
      const res = await this.$store.dispatch("VERIFY_USER_PIN", {
        ...transactionPinData,
      });

      if (res.status) {
        if (this.transactionData.transactionType === "airtime") {
          await this.makeAirtimeRequest();
        } else {
          this.makeDataRequest();
        }
      } else {
        this.isLoading = false;

        eventBus.$emit("trac-alert", {
          message: res.message || "Error: Invalid pin",
        });
      }
    },
    closeTransactionModal() {
      this.buyAirtimeModalCtrl = false;
      this.transferModalCtrl = false;
      this.currentAirtimeIndex = 1;
      this.currentTransferIndex = 1;
      this.responsePayload = null;
    },
    async makeDataRequest() {
      this.isLoading = true;
      const dataRequestRes = await this.$store.dispatch("MAKE_DATA_REQUEST", {
        walletDetails: this.walletDetails,
        body: {
          variation_code: this.transactionData.planSelected.variation_code,
          billersCode: this.transactionData.phoneNumber,
          serviceId: this.transactionData.service.telcosName,
          amount: +this.transactionData.planSelected.variation_amount,
          phone: this.transactionData.phoneNumber,
        },
      });

      if (dataRequestRes.status) {
        this.responsePayload = dataRequestRes.data;

        this.currentAirtimeIndex += 1;
      } else {
        eventBus.$emit("trac-alert", {
          message: dataRequestRes.message,
        });
      }
      this.isLoading = false;
    },
    async makeAirtimeRequest() {
      this.isLoading = true;
      const aitimeRequestRes = await this.$store.dispatch(
        "MAKE_AIRTIME_REQUEST",
        {
          walletDetails: this.walletDetails,
          body: {
            serviceId: this.transactionData.service.telcosName,
            amount: +this.transactionData.planSelected,
            phone: this.transactionData.phoneNumber,
          },
        }
      );

      if (aitimeRequestRes.status) {
        this.currentAirtimeIndex += 1;
        this.responsePayload = aitimeRequestRes.data;
      } else {
        eventBus.$emit("trac-alert", {
          message: aitimeRequestRes.message,
        });
      }
      this.isLoading = false;
    },
    async fetchTelcomsDataInfo() {
      const telcomsDataInfoRes = await this.$store.dispatch(
        "FETCH_TELCOMS_DATA_INFO",
        this.selectedNetwork
      );
    },
    async fetchWalletDetails() {
      // this.walletDetails = this.$store.getters["GET_WALLET_DETAILS"] || {})[
      //   "data"
      // ];

      await this.$store.dispatch("FETCH_WALLET_DETAILS");
      this.walletDetails = (this.$store.getters["GET_WALLET_DETAILS"] || {})[
        "data"
      ];
      // if (!this.walletDetails) {

      //   console.log(this.walletDetails);
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.wallet-bg {
  box-shadow: 0px 0px 20px rgba(22, 120, 176, 0.12);
}
.flex-two {
  flex: 2;
}
.wallet-info-cards {
  grid-template-columns: 1fr 30rem;

  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }
}
</style>