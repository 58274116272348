<template>
  <div class="mx-auto px-8 py-12">
    <h3 class="my-5">Confirm Request</h3>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-3 shadow-md p-4 my-6">
      <p class="text-xs font-bold">PAY</p>
      <div class="flex justify-end">
        <!-- <button class="text-primaryBlue font-bold text-xs">
          Change Amount
        </button> -->
      </div>
      <div class="flex items-center text-xs">
        <img
        width="100"
          class="p-2"
          :src="transactionData.service.img"
          alt=""
        />
        <div class="">
          <p class="font-bold">(+234) {{ transactionData.phoneNumber }}</p>
          <span class="text-gray-600 uppercase">{{ transactionData.service.telcosName }}</span>
        </div>
      </div>
      <div class="flex flex-col items-end justify-center">
        <p class="text-xs">Amount to top-up</p>
        <p class="text-lg font-extrabold" v-if="transactionData.transactionType === 'airtime'">{{ transactionData.planSelected | formatPrice(false) }}</p>
        <p class="text-xs font-extrabold" v-else>{{ transactionData.planSelected.name }}</p>
      </div>
    </div>
    <div class="p-4 my-6 border rounded-md border-primaryBlue bg-accentLight">
      <p class="text-xs font-extrabold">Transfer From</p>
      <div class="flex justify-between mt-4">
        <p class="text-xs">Wallet</p>
        <div class="flex flex-col items-end">
          <h2 class="font-extrabold text-lg">{{ walletData.amount | formatPrice(false) }}</h2>
          <span class="text-xs font-hairline">Available Balance</span>
        </div>
      </div>
    </div>
    <div class="flex justify-end mt-8">
      <trac-button @button-clicked="gotoEnterPinPage" class="">Enter PIN</trac-button>
    </div>
  </div>
</template>

<script>
export default {
  props:['transactionData', 'walletData'],
  created() {
    // console.log(this.transactionData);
  },
  methods: {
    gotoEnterPinPage() {
      this.$emit('got-enter-pin');
    }
  }
};
</script>

<style>
</style>